import {format} from 'date-fns';
import {OpeningHours} from '../modules/catalog/models';

export const MINUTES_PER_HOUR: number = 60

export const getDateOfWeek = (day: number): string => {
    switch (day) {
        case 1:
            return 'common:dayOfWeek.mo';
        case 2:
            return 'common:dayOfWeek.tu';
        case 3:
            return 'common:dayOfWeek.we';
        case 4:
            return 'common:dayOfWeek.th';
        case 5:
            return 'common:dayOfWeek.fr';
        case 6:
            return 'common:dayOfWeek.sa';
        case 0:
            return 'common:dayOfWeek.su';
        case 7:
            return 'common:dayOfWeek.su';
        default:
            return 'common:dayOfWeek.day';
    }
};

export const isBetween = (check: any, from: any, to: string | null) => {
    if (!check) {
        return true
    }

    return getTime(from) <= getTime(check) && (to === '' || to == null || getTime(check) <= getTime(to))
}

function getTime(string: string | number | Date) {
    // @ts-ignore
    if (string instanceof Date) {
        return string.getTime();
    }

    return new Date(string).getTime()
}

export const getDefaultTime = () => {
    return new Date().toISOString().split('T')[0];
}

export const getYearDate = (year: number) => {
    return new Date(year, 0, 1).toLocaleDateString('sv')
}

export const formatDate = (date: string | undefined, dateFormat: string | null) => {
    if (!date) {
        return null;
    }
    return format(new Date(date), dateFormat == null ? 'yyyy-MM-dd' : dateFormat);
}


export const dateFromString = (date: string) => {
    return new Date(Date.parse(date));
}


export const buildForShowList = (openingHours: OpeningHours[]): { [key: string]: string[] } => {
    if (!openingHours) {
        return {}
    }

    const map = {} as { [key: string]: string[] }

    openingHours
        .filter(oh => oh.dayOfWeek != null)
        // @ts-ignore
        .sort((oh1, oh2) => oh1?.dayOfWeek - oh2?.dayOfWeek)
        .forEach(oh => {
            if (!oh.dayOfWeek) {
                return;
            }

            if (!map[oh.dayOfWeek]) {
                map[oh.dayOfWeek] = [`${oh.timeFrom} - ${oh.timeTo}`]
                return
            }
            map[oh.dayOfWeek] = [...map[oh.dayOfWeek], `${oh.timeFrom} - ${oh.timeTo}`]
        })

    for (let dayOfWeek: number = 1; dayOfWeek < 8; dayOfWeek++) {
        if (!map[dayOfWeek]) {
            map[dayOfWeek] = []
            continue
        }
        map[dayOfWeek] = map[dayOfWeek].sort((a, b) => a.localeCompare(b))
    }
    return map;
}

export const getDayOfWeek = (): number => {
    return new Date().getDay()
}

export const getTodayInMinutes = (): number => {

    const date = new Date();
    return date.getMinutes() + (MINUTES_PER_HOUR * date.getHours())
}

export const initDate=():Date =>{
    const date = new Date();

    date.setMinutes(Math.floor(date.getMinutes() / 30) * 30);
    date.setHours(date.getHours() + 1);
    date.setMilliseconds(0);
    date.setSeconds(0);

    return date;
}

export const initDateReservation = (reservatioDate:any, when:any): Date => {
    if(reservatioDate != null){
        return reservatioDate as Date;
    }

    if(when!=null){
        return new Date(when)
    }



    return initDate();
}

export const isToday = (date:Date):boolean =>{
    const methodDate = new Date(date);

    if(!methodDate){
        return false
    }

    const today = new Date()
    methodDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
    return methodDate.getTime() === today.getTime();
}

export const getMinutesBetweenDates = (startDate: string, endDate: string) => {
  const diff = new Date(endDate).getTime() - new Date(startDate).getTime();

  return (diff / 60000);
}