import React, {useEffect, useRef, useState} from 'react';
import Calendar from 'react-calendar';
import {Portal} from '@restart/ui';
import CSS from 'csstype';
import TimeValue from './timeValue';
import useTranslations from '../../../../utils/hooks/useTranslations';
import {getWindowDimensions} from '../../../../helpers/commonHelper';
import {isToday} from '../../../../helpers/dateHelper';

interface ComponentProps {
    date: Date;
    setDate: (date: Date) => void;
    className?: string;
    onClose?: () => void;
    setTime?: boolean;
    minDate?: Date;
    allowOlder?:boolean;
    minDetail?:Detail
    maxDate?:Date
  customRef?: any

}

export type Detail = 'month' | 'year' | 'decade' | 'century';

interface TimeValues {
    date: Date;
    hours: number;
    minutes: number;

}

const CalendarPicker: React.FC<ComponentProps> = ({
                                                      date,
                                                      setDate,
                                                      className,
                                                      onClose,
                                                      setTime = true,
                                                      minDate = getAtStartOfDay(),
                                                      allowOlder = false,
                                                      minDetail='month',
                                                      maxDate,
                                                    customRef
                                                  }) => {
    const [showTime, setShowTime] = useState(false);
    const [position, setPosition] = useState<CSS.Properties>({
      zIndex:9999999999
    });
    const [show, setShow] = useState(false);
    const place = useRef<any>(null);
    const inputRef = useRef<any>(null);

    const [innerDate, setInnerDate] = useState(date);
    const [mouseOver, setMouseOver] = useState(false);
    const [timeValues, setTimeValues] = useState<TimeValues>({} as TimeValues);
    const [first, setFirst] = useState(true);
    const [timeOutId,setTimeOutId] = useState<NodeJS.Timeout | null>(null)
    const [minValues,setMinValues] = useState<{minHour:number,minMinute:number}>({minHour:0,minMinute:0})
    const {ts} = useTranslations();

    useEffect(() => {
        setTimeValues({
            ...timeValues,
            hours: date.getHours(),
            minutes: date.getMinutes(),
            date,
        });
    }, [0]);

    useEffect(() => {
        if(!setTime){
            return;
        }

        if (!isToday(timeValues.date)) {
            setMinValues({
                minHour: 0,
                minMinute: 0
            })
            return
        }
        const todayDate = new Date();

        setMinValues({
            minHour: todayDate.getHours(),
            minMinute: timeValues.hours === todayDate.getHours() && todayDate.getMinutes() > 30 ? 30 : 0
        })
    }, [timeValues.date, timeValues.hours])

    useEffect(() => {
        if (!isToday(timeValues.date) || !setTime) {
            return
        }

        const todayDate = new Date();

        if (timeValues.hours < todayDate.getHours()
            || (timeValues.hours === todayDate.getHours() && timeValues.minutes < todayDate.getMinutes())) {
            setTimeValues({
                ...timeValues,
                hours: todayDate.getMinutes() > 30 ? todayDate.getHours() + 1 : todayDate.getHours(),
                minutes: todayDate.getMinutes() < 30 ? 30 : 0
            })
        }


    }, [timeValues.date])

    const computePosition = (): CSS.Properties => {
        if (place.current === null) {
            return {top: 0, left: 0};
        }

        const location = place.current.getBoundingClientRect();
        const window = getWindowDimensions();
        const absoluteHeight = location.top + 440;

        if (location.top < 1) {
            setShow(false)
        }

        return {
            top: window.height < absoluteHeight ? window.height - 450 : location.top,
            left: location.left,
          zIndex:9999999999
        };
    };

    useEffect(() => {
        if (!show) {
            return
        }

        if(timeOutId){
            clearTimeout(timeOutId)
        }

        const timeOut = setTimeout(function watchScroll() {
            window.addEventListener('scroll', () => setPosition(computePosition()));
        }, 500);

        setTimeOutId(timeOut)

        return () => {
            window.removeEventListener('scroll', () => setPosition(computePosition()));
        };
    }, [show]);

    useEffect(() => {
        if (!show) {
            return
        }
        setPosition(computePosition())
    }, [show])

    useEffect(() => {
        if (first || !show) {
            setFirst(false);
            return;
        }

        const updateDate = timeValues.date;
        updateDate.setHours(timeValues.hours);
        updateDate.setMinutes(timeValues.minutes);
        updateDate.setSeconds(0);
        updateDate.setMilliseconds(0);
        setFormDate(timeValues.date);
        setDate(new Date(updateDate.valueOf()));
    }, [timeValues]);

    const setFormDate = (value: Date) => {
        setInnerDate(value);
        if (timeValues.date === value) {
            return;
        }

        setTimeValues({
            ...timeValues,
            date: value,
        });
    };
    const setHours = (value: number) => {
        setTimeValues({
            ...timeValues,
            hours: value,
        });
    };

    const setMinutes = (value: number, uptik: number) => {
        let hour = timeValues.hours + uptik;

        if (hour > 23) {
            hour = minValues.minHour;
        }

        if (hour < minValues.minHour) {
            hour = 23;
        }

        setTimeValues({
            ...timeValues,
            minutes: value,
            hours: hour,
        });
    };

    const toogle = () => {
        setShowTime(false);
        setShow(false);
        if (onClose) {
            onClose();
        }
    };

    const blur = () => {
        if (!mouseOver) {
            toogle();
        }
    };

    // TODO prenest do prekladu
    const getDateOfWeek = (day: number): string => {
        switch (day) {
            case 1:
                return 'common:dayOfWeek.mo';
            case 2:
                return 'common:dayOfWeek.tu';
            case 3:
                return 'common:dayOfWeek.we';
            case 4:
                return 'common:dayOfWeek.th';
            case 5:
                return 'common:dayOfWeek.fr';
            case 6:
                return 'common:dayOfWeek.sa';
            case 0:
                return 'common:dayOfWeek.su';
            case 7:
                return 'common:dayOfWeek.su';
            default:
                return 'common:dayOfWeek.day';
        }
    };

    return <>
      <div ref={place} onClick={() => setShow(!show)}>
        <input
          ref={inputRef}
          type={'text'}
          className={className != null ? className : ''}
          onBlur={blur}
          value={
            ts(getDateOfWeek(date.getDay())) +
            ' ' +
            date.getDate() +
            '.' +
            (date.getMonth() + 1) +
            '.' +
            date.getFullYear() +
            (setTime && ' ' + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()))
          }
          readOnly
        />
      </div>
      {!show ? null : (
        <Portal container={customRef ? customRef.current : document.body}>
          <div
            onClick={(e)=> {
              e.preventDefault()
            }}
            tabIndex={-1}
            className={'datePicker'}
            style={position}
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            onBlur={blur}
          >
            {showTime ? (
              <div>
                <div className={'datePicker__back'} onMouseDown={() => setShowTime(false)}/>
                <h5>{ts('datepicker:choseTime')}</h5>
                <div className={'datePicker__timeBoxes'}>
                  <TimeValue
                    value={timeValues.hours}
                    maxValue={23}
                    setValue={setHours}
                    title={ts('datepicker:hour')}
                    step={1}
                    minValue={minValues.minHour}
                  />
                  <TimeValue
                    value={timeValues.minutes}
                    maxValue={30}
                    setValue={setMinutes}
                    title={ts('datepicker:minute')}
                    step={30}
                    minValue={minValues.minMinute}
                  />
                </div>
                <button className={'datePicker__button'} onClick={toogle}>
                  {ts('datepicker:done')}
                </button>
              </div>
            ) : (
              <div>
                <Calendar
                  value={date}
                  defaultValue={date}
                  onChange={setFormDate}
                  minDate={allowOlder ? new Date('1.1.1911') : minDate}
                  minDetail={minDetail}
                  defaultView={minDetail}
                  maxDate={maxDate}
                />
                {setTime === true ? (
                  <button
                    className={'datePicker__button'}
                    onClick={() => {
                      inputRef.current.focus();
                      setShowTime(true);
                    }}
                  >
                    {ts('datepicker:continue')}
                  </button>
                ) : (
                  <button className={'datePicker__button'} onClick={toogle}>
                    {ts('datepicker:done')}
                  </button>
                )}
              </div>
            )}
          </div>
        </Portal>
      )}
    </>;
};

function getAtStartOfDay(): Date {
    const date = new Date();
    date.setMinutes(0);
    date.setHours(0);
    return date;
}

export default CalendarPicker;
