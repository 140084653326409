import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import React, { ReactNode } from 'react';
import { useConfiguration } from '../../configuration/useConfiguration';
import { TranslateFunction } from '../types';

interface GetTranslationResult {
  translation: string;
  containsHTML: boolean;
}

const useTranslations = () => {
  const { t, ...restTranslation } = useTranslation();
  const { nodeEnv } = useConfiguration();

  /**
   * helper_old function
   * @param key translation key
   * @param data translation option
   * @returns translation and whether it contains html tags
   */
  const getTranslation: TranslateFunction<GetTranslationResult> = (key, data, options) => {
    let translation = '';

    if (data?.context) {
      const keyWithContext = `${key}_${data.context}`;
      translation = t(keyWithContext, data, options);

      // if no translation for given context was found -> use default key then
      if (translation === keyWithContext) {
        translation = t(key, data, options);
      }
    }
    translation = translation || t(key, data, options);

    const containsHTML = translation.indexOf('/>') != -1 || translation.indexOf('</') != -1;

    return {
      translation,
      containsHTML,
    };
  };

  /**
   * This function shoud be used mostly. It returns translations wrapped into span element. This span element has set props for
   * Lokalise tool.
   *
   * @param key translation key
   * @param data translation option
   * @param options next-translate options
   * @returns translation in <x-translate> element which has lokalise data attributes set
   */
  const translate: TranslateFunction<ReactNode> = (key, data, options) => {
    const { components, ...rest } = data || {};
    const { translation, containsHTML } = getTranslation(key, data, options);
    if (nodeEnv === 'production') {
      if (components) {
        return <Trans i18nKey={key} values={rest} components={components} />;
      }

      return !containsHTML ? translation : <TranslationElement dangerouslySetInnerHTML={{ __html: translation }} />;
    }

    const dataAttributes = {
      // 'data-lokalise': true,
      // 'data-key': key.split(':')[1].replace(/\./g, '::'),
      'data-key': key,
    };

    if (components) {
      return (
        <TranslationElement {...dataAttributes}>
          <Trans i18nKey={key} values={rest} components={components} />
        </TranslationElement>
      );
    }

    return !containsHTML ? (
      <TranslationElement {...dataAttributes}>{translation}</TranslationElement>
    ) : (
      <TranslationElement {...dataAttributes} dangerouslySetInnerHTML={{ __html: translation }} />
    );
  };

  /* *
   * This function should be used only when you actually need translations to be STRING. For example when passing translations into
   * props like placeholder, aria attributes, data attributes and so on...
   *
   * @param key translation key
   * @param data translation option
   * @param options next-translate options
   * @returns translation in string
   * @throws error when data.components is passed or translations contains html elements
   */
  const translateToString: TranslateFunction<string> = (key, data, options) => {
    data = data || {};

    const { translation, containsHTML } = getTranslation(key, data, options);

    if ('components' in data) {
      throw new Error('Cannot pass components into string translation. Use `t` function instead');
    }

    if (containsHTML) {
      throw new Error(`Cannot convert transaltion for key '${key}' into string`);
    }

    return translation;
  };

  return {
    t: translate,
    ts: translateToString,
    ...restTranslation,
  };
};

/* *
 * Renders Custom <x-translation /> HTML element
 *
 * @param props
 */
const TranslationElement: React.FC<React.AllHTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
  return React.createElement('x-translation', rest, children);
};

export default useTranslations;
